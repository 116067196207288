:root {
    --rwth-primary: #00549f;
    --rwth-danger: #f14668;
    --rwth-success: #48c78e;
    --rwth-link: var(--rwth-primary);
    --rwth-grey: hsl(0, 0%, 48%);
    --bs-font-sans-serif: "Helvetica", "Arial", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial,
        "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
        "Noto Color Emoji";
}

a {
    color: var(--rwth-link);
}

.form-control {
    border-radius: unset;
}

.btn {
    border-radius: unset;
    border: none;
}

.btn:hover {
    filter: brightness(95%);
}

.btn-primary,
.btn-primary:hover {
    background-color: var(--rwth-primary);
}

.btn-danger,
.btn-danger:hover {
    background-color: var(--rwth-danger);
}

.btn-success,
.btn-success:hover {
    background-color: var(--rwth-success);
}

.btn-secondary,
.btn-secondary:hover {
    background-color: var(--rwth-grey);
}

.modal-backdrop {
    background-color: rgba(255, 255, 255, 0.5);
}

.modal {
    backdrop-filter: blur(4px);
    padding-left: 0 !important;
}

.modal-content {
    box-shadow: 0px 0px 250px -100px;
    border-radius: unset;
    border: none;
}

.modal-header {
    border-bottom: unset;
    background-color: white;
}

.modal-title {
    font-weight: 700;
}

.modal-footer {
    border-top: solid 1px #f4f4f4;
    background-color: white;
}
